.slider-container{
  width: 10px;
  height: 200px;
  border: white;
  border-width: 2px;
  border-style: solid;
  background-color: transparent;
  position: relative;
}

.slider-container:hover{
  cursor: pointer;
}

.slider-text{
  color:white;
  position:absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 700px) {
  .slider-container{
    height: 100px;
  }
}

@media only screen and (max-width: 300px) {
  .slider-container{
    height: 50px;
  }
}