.overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    z-index: 10;
}

.box{
    position: absolute;
    left: 5%;
    top: 5%;
    height: 90%;
    width: 90%;
    background: white;
}

.box > *{
    user-select: text;
}

.box-links{
    float: none !important;
    color: black !important;
}

.button {
    background-color: #7f7f7f;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
}

.part {
    display: inline-block;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}
.center {
    text-align: center;
}
.bold{
    font-weight: bolder;
}
.overlay > p {
    text-align: left;
    margin-left: 30px;
}
