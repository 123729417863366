.App {
  text-align: center;
  min-width: 500px;
}

ul.video{
  padding: 0;
    list-style-type: none;
}

li.video{
  list-style-type: none;
  background: gray;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom:2px dotted black;
}

li.video.selected{
  background: blue !important;
}

.filter-container {
  /* left: 50%;
  position: absolute;
  transform: translateX(-50%); */
}

.config-container {
  /* left: 50%;
  position: absolute;
  transform: translateX(-50%); */
}


li.video:hover{
  background-color: lightblue;
  cursor: pointer;
}

svg{
  paint-order: stroke fill;
  stroke-width: 1;
  stroke: black;
}