.settings-container{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.60);
    z-index: 10;
}

.settings-container-box{
    position: absolute;
    opacity: 0.6;
    left: 20%;
    top: 20%;
    width: 60%;
    width: 60%;
    background: white;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
}

.settings-text-name{
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    display: inline-block;
}
.settings-text-value{
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    display: inline-block;
}

.settings-container-record{
    display: flex;
    flex-direction: row;
    width: 50%;
    align-self: center;
    justify-content: space-evenly;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: gray;
    padding-top: 10px;
    padding-bottom: 10px;
}

.add-margin{
    margin-top: 50px;
    background-color: transparent;
}

.button {
    background-color: #7f7f7f;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
}
