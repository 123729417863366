*{
    user-select: none;
}
p.controltime {
    left: 0;
    font-size: small;
    position: absolute;
    color: white;
    bottom: 10px;
}

.playercontainer{
    margin: 0 auto;
    width: 80%;
    position: relative;
}

.seekbar{
    cursor:pointer;
}

.drawing-mode{
    cursor:crosshair;
}

.hidden{
    cursor: none;
}

.player{
    position: relative; 
    left: 0; 
    top: 0;
    width: 100%; 
    height: 100%;
    background: black;
}

.player.no-source{
    background: lightblue;
}

.subtitlecontainer{
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.controlscontainer{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.controls{
    width: 32px;
    height: 32px;
    color: white;
}

.left{
    margin-right: 20px;
}

.right{
    margin-left: 20px;
}

.main-controls{
    display: inline-block;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
}

.controls-volume{
    top: 50%;
    transform:translateY(-50%);
    position: absolute;
    right: 10%;
    transition: opacity 3s,
}

.video-filter{
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.video-filter-black{
    background-color: black;
}
.video-filter-blur{
    backdrop-filter: blur(15px);
    width: 101%;
}
.video-filter-blur-extra{
    backdrop-filter: blur(45px);
    width: 101%;
}
.video-filter-blur-extreme{
    backdrop-filter: blur(80px);
    width: 101%;
}

@media only screen and (max-width: 1024px) {
    .playercontainer{
        width: 100%;
    }

    .controls{
        display: none;
    }
}