.filters-container{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    z-index: 10;
}

.filters-container-close{
    position: absolute;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 20px;
    background-color: #ddd;
    right:-20px;
    top:-20px;
}

.filters-container-search{
    width: 40px;
    height: 40px;
}

.filters-container-close:hover {
    background-color: #fff;
}

.filters-container-body{
    position: absolute;
    left: 10%;
    top: 5%;
    height: 90%;
    width: 80%;
    background: lightgray;
}

.filters-container-input-container{
    text-align: center;
    margin: auto;
    margin-top: 20px;
    width: 50%;
}

.filters-container-input{
    width: 100%;
}

.filter-files{
    overflow: auto;
    height: 77%;
    margin-top: 50px;
}

.file-record2{
    height: 150px;
    width: 240px;
    margin: auto;
    display: flex;
    background: darkgray;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    cursor:pointer;
    position: relative;
}

.file-record2.ready{
    background-color: aquamarine;
}

.file-record2 > img{
    height: 100%;
    width: 40%;
    background-color: gray;
    object-fit: contain;
}

.file-record2 > img:hover{
    cursor: pointer;
}

.file-record2 .title{
    margin-left: 20px;
    font-size: 10px;
}

.file-record2-container{
    text-align: left;
}

.file-record2 .ready{
    background-color: green;
}

.file-record2-icon{
    height:100%;
    margin-right:10px;
}

.file-record2-icon:hover{
    cursor: pointer;
}