    .navbar {
        overflow: hidden;
        background-color: #333;
    }

  .navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    display: flex;
  }
  
  .dropdown {
    float: left;
    overflow: hidden;
  }
  
  .dropdown .dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropbtn.blue{
    background-color: blue;
  }
  
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: darkgray;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10;
  }

  .dropdown-content.files > span{
        margin-left: 10px;
        margin-top: 5px;
        display: inline-block;
        width: 70px;
        text-align: left;
  }

  .dropdown-content.files > input{
    margin-left: 10px;
    margin-right: 10px;
  }

  .dropdown-content.files > a{
    background: #4f4f4d;
    color: #fff;
    margin-top: 10px;
    text-align: center;
  }
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #aaa;
  }

  .dropdown-content div:hover {
    background-color: #999;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  input.hidden {
      display: none;
  }